/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useLayoutEffect, useRef } from "react";
import { images } from "../../constants";
import { motion } from "framer-motion";
import { staggerContainer, textVariant } from "../../utils/motion";
import "./Header.css";
import gsap from "gsap";

const Header = () => {
  const slantedImgRef = useRef();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      let tween = gsap
        .to(".slanted-img img", {
          yPercent: -100,
          repeat: -1,
          duration: 10,
          ease: "linear",
        })
        .totalProgress(0.5);

      gsap.set(".slanted-img img", { yPercent: -50 });

      // window.addEventListener('wheel', () => {})
    }, slantedImgRef);

    return () => ctx.revert();
  }, []);

  return (
    <div
      className="app__header app__wrapper-head section__padding-head"
      id="home"
    >
      <div className="app__wrapper_info">
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.25 }}
        >
          <motion.div variants={textVariant(0.2)}>
            <p className="p__cormorant-small">VELOCITY 678 ARE</p>
            <br />
            <h1 className="app__header-h1">CONTENT NAVIGATORS</h1>
            <p className="p__cormorant" style={{ margin: "2rem 0" }}>
              We will help your brand or products to navigate the storm of
              content in Tik Tok by combining the latest trends with your
              objectives to drive business results in the most engaging way to
              your target audiences.
            </p>
          </motion.div>
        </motion.div>
      </div>

      <div
        className="app__wrapper_img-head"
        ref={slantedImgRef}
        style={{ position: "relative" }}
      >
        <div className="slanted-img">
          {Array(2).fill(<img src={images.header} alt="header_img" />)}
        </div>
      </div>
    </div>
  );
};

export default Header;

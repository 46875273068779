import v678 from "../assets/Logo - V678.svg";
import header from "../assets/header.webp";
import marquee from "../assets/VELOCITY678.svg";
import phone from "../assets/Phone.png";
import circle from "../assets/circle1.png";
import circle_m from "../assets/circle-m.png";
import live1 from "../assets/live1.png";
import live2 from "../assets/live2.png";
import live3 from "../assets/live3.png";
import live4 from "../assets/live4.png";
import faeture1 from "../assets/feature1.png";
import feature2 from "../assets/feature2.png";
import feature3 from "../assets/feature3.png";
import bar from "../assets/phone-bar.png";
import gif1 from "../assets/gif/multi-channel-network.gif";
import gif2 from "../assets/gif/influencer-marketing-training.gif";
import gif3 from "../assets/gif/social-commerce.gif";
import swiper from "../assets/swipe.png";
import tiktok1 from "../assets/LivestreamGif/01.gif";
import tiktok2 from "../assets/LivestreamGif/02.gif";
import tiktok3 from "../assets/LivestreamGif/03.gif";
import tiktok4 from "../assets/LivestreamGif/04.gif";
import tiktok5 from "../assets/LivestreamGif/05.gif";
import tiktok6 from "../assets/LivestreamGif/06.gif";
import tiktok7 from "../assets/LivestreamGif/07.gif";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  v678,
  header,
  marquee,
  phone,
  circle,
  circle_m,
  live1,
  live2,
  live3,
  live4,
  faeture1,
  feature2,
  feature3,
  bar,
  gif1,
  gif2,
  gif3,
  swiper,
  tiktok1,
  tiktok2,
  tiktok3,
  tiktok4,
  tiktok5,
  tiktok6,
  tiktok7,
};

import React, { useEffect, useState, useRef } from "react";
import { f3, images } from "../../constants";
import { motion } from "framer-motion";
import { staggerContainer, textVariant, fadeIn } from "../../utils/motion";
import "./ThirdFeature.css";
import MediaQuery from "react-responsive";

import { InView } from "react-intersection-observer";

const VideoView = ({ videoUrl }) => {
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isVisible) {
      videoRef.current.play();
    } else {
      if (videoRef.current.play) {
        videoRef.current.pause();
      }
    }
  }, [isVisible]);

  return (
    <InView onChange={(inView, entry) => setIsVisible(inView)}>
      <video loop ref={videoRef} autoPlay>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag
      </video>
    </InView>
  );
};

const ThirdFeature = () => (
  <div className="app__feature-t app__wrapper section__padding">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <span className="app__feature-textbg">
        SOCIAL
        <br /> COMMERCE
      </span>
      <motion.div
        className="third-video"
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
      >
        <div style={{ zIndex: "2" }}>
          <motion.div variants={textVariant(0.2)}>
            <MediaQuery maxWidth={450}>
              <img src={images.gif3} alt="GIF3 SC" />
            </MediaQuery>
            <VideoView videoUrl={f3} />
          </motion.div>
        </div>
      </motion.div>
    </div>
    <div className="app__feature-third">
      <motion.div
        className="text-third"
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
      >
        <motion.div variants={fadeIn("up", "easeIn", 0.5, 1)}>
          <p className="p__cormorant-smallwhite">WHAT WE DO</p>
          <br />
          <h1 className="headtext__cormorant-white">SOCIAL COMMERCE</h1>

          <div className="app__third-content">
            <p className="p__cormorant-white">
              As an official Tik Tok Shop partner, we provide one-stop social
              commerce solutions to brands and merchants to drive growth,
              engagement and sales. <br />
              <br />
              From social commerce strategies to live streaming production to
              3PL management, we’ve got all your social commerce needs covered.
              With our team of experts and proven track record, we’ll help to
              make social commerce exciting for everyone.
            </p>
          </div>
        </motion.div>
      </motion.div>
    </div>
  </div>
);

export default ThirdFeature;

import React from 'react';

import { AboutUs, FirstFeature, Footer,  Header, SecondFeature, ThirdFeature } from './container';
import { Navbar, Marquee } from './components';
import './App.css';


const App = () => (
  <div>
    <Navbar />
    <Header />
    <Marquee />
    <AboutUs />
    <FirstFeature />
    <SecondFeature />
    <ThirdFeature />
    <Footer />
  </div>
);

export default App;

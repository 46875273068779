/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import {
  images,
  live1,
  live2,
  live3,
  live4,
  live5,
  live6,
  live7,
} from "../../constants";
import { motion } from "framer-motion";
import { staggerContainer, fadeIn, zoomIn } from "../../utils/motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "./AboutUs.css";
import MediaQuery from 'react-responsive';

const AboutUs = () => { 
  const slides = [live1, live2, live3, live4, live5, live6, live7];
  const slidesGif = [
    images.tiktok1,
    images.tiktok2,
    images.tiktok3,
    images.tiktok4,
    images.tiktok5,
    images.tiktok6,
    images.tiktok7,
  ];

  const handleChangeSlide = (e) => {
    const srcUrl = e.slides[e.realIndex]?.dataset?.videoUrl;
    const srcUrlGif = e.slides[e.realIndex]?.dataset?.gifUrl;
    const screenWidth = window.innerWidth;
    if (screenWidth < 450) {
      if (srcUrlGif && srcUrlGif !== "") {
        const hlSlide = document.getElementById("highlight-slide-gif");
        hlSlide.className = "";
        hlSlide.innerHTML = "";
        const newEl = document.createElement("img");
        newEl.src = srcUrlGif;
        newEl.style.width = "100%";
        const buttonEl = document.createElement("div");
        buttonEl.classList.add("button");
        hlSlide.appendChild(newEl);
        hlSlide.appendChild(buttonEl);
        setTimeout(() => {
          hlSlide.className = "visible";
        }, 100);
      }
    } else {
      if (srcUrl) {
        const hlSlide = document.getElementById("highlight-slide");
        hlSlide.className = "";
        hlSlide.innerHTML = "";
        const newEl = document.createElement("video");
        newEl.src = srcUrl;
        newEl.style.width = "100%";
        newEl.loop = true;
        const buttonEl = document.createElement("div");
        buttonEl.classList.add("button");
        hlSlide.appendChild(newEl);
        hlSlide.appendChild(buttonEl);
        setTimeout(() => {
          hlSlide.className = "visible";
          newEl.play();
        }, 100);
      }
    }
  };

  // const handleChangeSlideGif = (e) => {
  //   const srcUrl = e.slides[e.realIndex].dataset.gifUrl;
  //   console.log(e.slides[e.realIndex]);
  //   if (srcUrl) {
  //     const hlSlide = document.getElementById("highlight-slide-gif");
  //     hlSlide.className = "";
  //     hlSlide.innerHTML = "";
  //     const newEl = document.createElement("img");
  //     newEl.src = srcUrl;
  //     newEl.style.width = "100%";
  //     hlSlide.appendChild(newEl);
  //     setTimeout(() => {
  //       hlSlide.className = "visible";
  //     }, 100);
  //   }
  // };

  return (
    <div className="app__aboutus flex__center section__padding-about">
      <div className="app__aboutus-overlay flex__center">
        <div className="app__aboutus-title">
          <motion.div
            variants={staggerContainer}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.25 }}
            // style={{ padding: "0 2rem" }}
          >
            <motion.div variants={fadeIn("up", "easeIn", 0.2, 1)}>
              <p className="p__cormorant-smallblack">VELOCITY 678 ARE</p>
              <h2 className="headtext__cormorant">BROAD-CASTING</h2>
              <p className="p__cormorant-black">
                Our inhouse talent and hosts allows you to reach a wider
                audience and interact with them in real-time. It's a
                cost-effective way to share info and host events, and offers
                flexibility in terms of location and scheduling.
              </p>
            </motion.div>
          </motion.div>
        </div>
        <motion.div
          variants={staggerContainer}
          whileInView="show"
          initial="hidden"
          viewport={{ once: true, amount: 0.25 }}
          style={{ width: "100%" }}
        >
          <div>
            <motion.div variants={zoomIn(0.2, 0.6)}>
              <div className="semi-circle"></div>
            </motion.div>
          </div>
        </motion.div>
      </div>
      <MediaQuery minWidth={768}>
      <div className="swiper__container">
        <Swiper
          className="slider"
          spaceBetween={40}
          slidesPerView={3}
          // slidesOffsetBefore={-520}
          // slidesOffsetAfter={520}
          // centeredSlides
          loop
          onSlideChange={handleChangeSlide}
          breakpoints={{
            991: {
              slidesPerView: 5,
            },
          }}
          navigation
          modules={[Navigation]}
        >
          {slides.map((val) => {
            return (
              <div>
                <SwiperSlide data-video-url={val}>
                  <video width={300} height={400}>
                    <source src={val} type="video/mp4" />
                  </video>
                </SwiperSlide>
              </div>
            );
          })}
          <div id="highlight-slide" className="mobile-phone"></div>
          {/* <div className="button"></div> */}

          <div className="mobile-bar">
            <img src={images.bar} alt="bar" />
          </div>
          <div className="swiper-bar">
            <img src={images.swiper} alt="swiper" />
          </div>
        </Swiper>
      </div>
      </MediaQuery>
      <MediaQuery maxWidth={450}>
      <div className="swiper__container-mobile">
        <Swiper
          className="slider__gif"
          // spaceBetween={40}
          slidesPerView={1}
          // slidesOffsetBefore={425}
          // slidesOffsetAfter={-425}
          // centeredSlides
          loop
          onSlideChange={handleChangeSlide}
          navigation
          modules={[Navigation]}
        >
          {slidesGif.map((val) => {
            return (
              <div>
                <SwiperSlide data-gif-url={val}>
                  <img src={val} width={300} height={560} />
                </SwiperSlide>
              </div>
            );
          })}
          <div id="highlight-slide-gif"></div>
          {/* <div className="button"></div> */}
          <div className="mobile-bar">
            <img src={images.bar} alt="bar" />
          </div>
          <div className="swiper-bar">
            <img src={images.swiper} alt="swiper" />
          </div>
        </Swiper>
      </div>
      </MediaQuery>
    </div>
  );
};

export default AboutUs;

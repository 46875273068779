/* eslint-disable react/style-prop-object */
import React, { useEffect, useState, useRef } from "react";
import { f2, images } from "../../constants";
import { motion } from "framer-motion";
import { staggerContainer, textVariant, fadeIn } from "../../utils/motion";
import "./SecondFeature.css";
import MediaQuery from "react-responsive";

import { InView } from "react-intersection-observer";

const VideoView = ({ videoUrl }) => {
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isVisible) {
      videoRef.current.play();
    } else {
      if (videoRef.current.play) {
        videoRef.current.pause();
      }
    }
  }, [isVisible]);

  return (
    <InView onChange={(inView, entry) => setIsVisible(inView)}>
      <video loop ref={videoRef}>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag
      </video>
    </InView>
  );
};

const SecondFeature = () => (
  <div
    className="app__feature-s app__wrapper-s section__padding"
    style={{ overflow: "hidden" }}
  >
    <div className="app__wrapper_img app__wrapper_img-reverse second__mobile">
      <span className="app__second-textbg">
        INFLUENCER
        <br />
        MARKETING & <br />
        TRAINING
      </span>
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
      >
        <div style={{ zIndex: "2" }}>
          <motion.div variants={textVariant(0.2)}>
            <MediaQuery maxWidth={450}>
              <img src={images.gif2} alt="GIF2 IMT" />
            </MediaQuery>
            <VideoView videoUrl={f2} />
          </motion.div>
        </div>
      </motion.div>
    </div>
    <div className="app__feature-second">
      <motion.div
        className="text-second"
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
      >
        <motion.div variants={fadeIn("up", "easeIn", 0.5, 1)}>
          <p className="p__cormorant-smallwhite">WHAT WE DO</p>
          <br />
          <h1 className="headtext__cormorant-white">
            INFLUENCER MARKETING & TRAINING
          </h1>

          <div className="app__second-content">
            <p className="p__cormorant-white">
              Are you an aspiring influencer looking to make a splash in the
              world of social media? <br />
              <br />
              Look no further! Our team of experts is here to help you take your
              influencer journey to the next level. With our influencer
              marketing and training services, you'll learn the ins and outs of
              building your brand, creating engaging content, and leveraging
              your influence to drive results. From strategy development to
              content creation, we've got you covered. <br />
              <br />
              Join us and become the ultimate influencer you've always dreamed
              of being!{" "}
            </p>
          </div>
        </motion.div>
      </motion.div>
    </div>
    <div className="app__wrapper_img app__wrapper_img-reverse second__desktop">
      <span className="app__second-textbg">
        INFLUENCER
        <br />
        MARKETING & <br />
        TRAINING
      </span>
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
      >
        <div style={{ zIndex: "2" }}>
          <motion.div variants={textVariant(0.2)}>
            <VideoView videoUrl={f2} />
          </motion.div>
        </div>
      </motion.div>
    </div>
  </div>
);

export default SecondFeature;

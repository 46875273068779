import React, { useEffect, useState, useRef } from "react";
import { f1, images } from "../../constants";
import { motion } from "framer-motion";
import { staggerContainer, textVariant, fadeIn } from "../../utils/motion";
import "./FirstFeature.css";
import MediaQuery from 'react-responsive';

import { InView } from "react-intersection-observer";

const VideoView = ({ videoUrl }) => {
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isVisible) {
      videoRef.current.play();
    } else {
      if (videoRef.current.play) {
        videoRef.current.pause();
      }
    }
  }, [isVisible]);

  return (
    <InView onChange={(inView, entry) => setIsVisible(inView)}>
      <video loop ref={videoRef}>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag
      </video>
    </InView>
  );
};

const FirstFeature = () => (
  <div className="app__feature-first app__wrapper section__padding" id="about">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <span className="app__feature-textbg">
        MULTI-
        <br />
        CHANNEL
        <br />
        NETWORK
      </span>
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
      >
        <div style={{ zIndex: "2" }}>
          <motion.div variants={textVariant(0.2)}>
            <VideoView videoUrl={f1} />
            <MediaQuery maxWidth={450}>
            <img src={images.gif1} alt="GIF MCN" />
            </MediaQuery>
          </motion.div>
        </div>
      </motion.div>
    </div>
    <div className="app__feature-info">
    <motion.div
        className="text-first"
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
      >
      <motion.div variants={fadeIn("up", "easeIn", 0.5, 1)}>
        <p className="p__cormorant-smallwhite">WHAT WE DO</p>
        <br />
        <h1 className="headtext__cormorant-white">MULTI-CHANNEL NETWORK</h1>

        <div className="app__first-content">
          <p className="p__cormorant-white">
            Go bigger and faster with Velocity 678. We help you reach your
            fullest potential, offers multiple services and solutions to
            individual content creators, brands, and production studios. <br />
            <br />
            Managing your budget or your client? Yuck! In Velocity you can focus
            on creating awesome content while we will take care of the rest.{" "}
            <br />
            <br />
            We know creating content is hard and tedious that’s why you can meet
            up with fellow creators to share and learn from each other.{" "}
          </p>
        </div>
      </motion.div>
      </motion.div>
    </div>
  </div>
);

export default FirstFeature;

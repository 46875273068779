import React from "react";
import { Newsletter } from "../../components";
import "./Footer.css";

const Footer = () => (
  <div className="app__footer section__padding-footer" id="login">
    <Newsletter />

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">VELOCITY678</h1>
        <p className="p__opensans-footer">
          139B, JALAN SUTERA TANJUNG 8/2, <br />
          TAMAN SUTERA UTAMA, <br />
          81300 SKUDAI, JOHOR
        </p>
        <p className="p__cormorant-smallblack" style={{ marginTop: "5rem"}}>
        +607 550 4900
        </p>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__cormorant-smallwhite">© 2023 Velocity 678.</p>
    </div>
  </div>
);

export default Footer;
